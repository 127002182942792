import { Button, Modal, Box } from "@mui/material";
import "./styles.scss";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import RegisterForm from "share/registerForm";
import { courseOfflineForm } from "../../const";
import { useDispatch, useSelector } from "react-redux";
import { addCourseToCart } from "core/redux/actions/cartAction";
import { Link, useLocation } from "react-router-dom";
function FixedBottom({ ...props }) {
  const dispatch = useDispatch()
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const fillDataCourse = (comboName) => {
    const courseIndex = courseOfflineForm.findIndex(
      (item) => item.name === comboName
    );
    if (courseIndex !== -1) {
      const selectedCourse = courseOfflineForm[courseIndex];

      const updatedProduct = {
        type: selectedCourse.type,
        course_name: selectedCourse.name,
        course: selectedCourse.course,
        img: selectedCourse.img,
        user: userInfo,
        count: 1,
        price: selectedCourse.price,
        checkout: selectedCourse.checkout,
      };

      dispatch(addCourseToCart(updatedProduct));
    } else {
      console.log("Course not found in courseOfflineForm.");
    }
  }
  return (
    <div className="tfs-fixed-bottom">
      <div className="container d-flex align-items-center justify-content-center h-100">
        <div className="img-register">
          {!userInfo.name ? (
            // If user is not logged in, show the login redirect button
            <Link
              to="/dang-nhap"
              state={{ from: location }} // Pass the current location
              className="tfs-btn-register animate-ring"
            >
              Đăng Nhập Để Mua Ngay
            </Link>
          ) : (
            // If user is logged in, show the original "Mua ngay" button
            <Button
              className="tfs-btn-register animate-ring"
              onClick={() => {
                fillDataCourse(props.course.name);
              }}
            >
              Mua ngay
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FixedBottom;
