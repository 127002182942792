import React, { useEffect, useState } from 'react';
import "./styles.scss";
import { isEmpty, size } from 'lodash';
import Loading from "share/loading";
import FormatNumberic from "share/formatNumber";
import StationDisplay from 'app/modules/CourseOnlineModules/courseOnlineDetails/components/stationDisplay/stationDisplay';
import { faTents, faFlag } from '@fortawesome/free-solid-svg-icons';
import { pyramidStations } from './const';

const PyramidDisplay = ({ data, onPackClick, stationData, popupVisible, isScreensplit = true, onStationClick }) => {
    const [pyramidData, setPyramidData] = useState("");
    const [station, setStation] = useState([]);
    const [popupVisibility, setpopupVisibility] = useState([false, false, false]);
    const [popupContent, setPopupContent] = useState(pyramidStations);
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        setPyramidData(data);
    }, [data]);

    useEffect(() => {
        setStation(stationData);
    }, [stationData]);

    useEffect(() => {
        setpopupVisibility(popupVisible);
    }, [popupVisible]);

    const getIconPosition = (isScreensplit, index) => {
        let screenWidth = window.innerWidth;
        let positions = {};

        const defaultSize = screenWidth > 1600 ? "4x" : screenWidth > 767 ? "3x" : "3x";

        if (isScreensplit) {
            const breakpoints = [
                { width: 1600, leftIcon: ["6vw", "21vw"], rightIcon: "2vw", topIcon: ["340px", "210px", "-30px"] },
                { width: 1300, leftIcon: ["2vw", "21vw"], rightIcon: "-2vw", topIcon: ["340px", "210px", "-40px"] },
                { width: 1023, leftIcon: ["2vw", "21vw"], rightIcon: "-2vw", topIcon: ["320px", "210px", "-30px"] },
                { width: 767, leftIcon: ["2vw", "21vw"], rightIcon: "-2vw", topIcon: ["220px", "130px", "-30px"] },
                { width: 576, leftIcon: ["10vw", "51vw"], rightIcon: "10vw", topIcon: ["180px", "120px", "-25px"] },
                { width: 0, leftIcon: ["4%", "52.5vw"], rightIcon: "4%", topIcon: ["180px", "120px", "-25px"] }
            ];
            const breakpoint = breakpoints.find(b => screenWidth > b.width);

            positions = {
                leftIcon: index === 0 ? undefined : breakpoint.leftIcon[index - 1],
                rightIcon: index === 0 ? breakpoint.rightIcon : undefined,
                topIcon: breakpoint.topIcon[index],
                size: defaultSize
            };
        } else {
            const breakpoints = [
                { width: 1600, leftIcon: ["30vw", "50vw"], rightIcon: "27vw", topIcon: ["340px", "210px", "-40px"] },
                { width: 1300, leftIcon: ["30vw", "51vw"], rightIcon: "25vw", topIcon: ["360px", "230px", "-30px"] },
                { width: 1023, leftIcon: ["25vw", "51vw"], rightIcon: "21vw", topIcon: ["360px", "230px", "-30px"] },
                { width: 767, leftIcon: ["25vw", "51.5vw"], rightIcon: "20vw", topIcon: ["240px", "150px", "-30px"] },
                { width: 576, leftIcon: ["25vw", "51.5vw"], rightIcon: "15vw", topIcon: ["180px", "110px", "-25px"] },
                { width: 0, leftIcon: ["5vw", "51.5vw"], rightIcon: "8vw", topIcon: ["180px", "120px", "-25px"] }
            ];
            const breakpoint = breakpoints.find(b => screenWidth > b.width);

            positions = {
                leftIcon: index === 0 ? undefined : breakpoint.leftIcon[index - 1],
                rightIcon: index === 0 ? breakpoint.rightIcon : undefined,
                topIcon: breakpoint.topIcon[index],
                size: defaultSize
            };
        }

        return positions;
    };

    const handleContainerClick = (pack) => {
        onPackClick(pack);
    }

    const renderPrice = (price) => {
        return (
            <div className="headline-h4 course-offline-promo-price">
                <FormatNumberic num={isHover ? price : 0} />
            </div>
        );
    };

    const handleMouseEnter = (index) => {
        const updatedVisibility = [...popupVisibility];
        updatedVisibility[index] = true;
        setpopupVisibility(updatedVisibility);
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setpopupVisibility([false, false, false]);
        setIsHover(false);
    };
    return (!isEmpty(pyramidData) && !isEmpty(station)) ? (
        <div>
            <div>
                {
                    stationData.map((s, index) => {
                        const { leftIcon, rightIcon, topIcon, size } = getIconPosition(isScreensplit, index);
                        return (
                            <div key={index} className='k'
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <StationDisplay
                                    icon={index === 2 ? faFlag : faTents}
                                    updatedColor={s.color}
                                    topIcon={topIcon}
                                    rightIcon={rightIcon}
                                    leftIcon={leftIcon}
                                    size={size}
                                    onClick={() => onStationClick(s)} // Ensure this is passed correctly
                                />
                                {popupVisibility[index] && (
                                    <div
                                        className={`popup-station ${popupVisibility[index] ? 'show' : ''}`}
                                        style={{
                                            top: topIcon,
                                            ...(index === 0
                                                ? { right: rightIcon }
                                                : { left: leftIcon }),
                                        }}
                                    >
                                        <div style={{ fontWeight: "bolder" }}>{popupContent[index].stationNameEng}</div>
                                        {popupContent[index].stationNameVie}
                                        {popupContent[index].price && (
                                            <div className="d-flex align-items-center justify-content-center m-2">
                                                {renderPrice(popupContent[index].price)}
                                            </div>
                                        )}
                                        {popupContent[index].testDetail && popupContent[index].testDetail.map((detail, detailIndex) => (
                                            <div className='text-start' key={detailIndex} style={{ fontSize: '0.8em' }}>- {detail}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    })}
            </div>
            <div className="wrapper mt-4">
                {
                    pyramidData.map((floor, floorIndex) => (
                        <React.Fragment key={floorIndex}>
                            <div className="layer">
                                {
                                    floor.map((pack, packIndex) => (
                                        <div
                                            key={packIndex}
                                            className="pack"
                                            style={{
                                                backgroundColor: pack.color,
                                                textAlign: 'center',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                className='container'
                                                style={{ width: '100%', height: '100%' }}
                                                onClick={() => handleContainerClick(pack)}
                                            >
                                                <p className='m-0' style={{ margin: 0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {pack.label}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    ) : (
        <Loading />
    );
}

export default PyramidDisplay;