import React from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTopButton from "app/components/scrollToTop";
// import ContactForm from "share/contactForm";
// import { LEFT_MENU, RIGHT_MENU } from "app/const/Footer";
import logoVnPay from "assets/images/logoVnpayNgang.png";
import "./styles/styles.scss";
import { NavLink } from "react-router-dom";

function LPEFooter() {
  const { t } = useTranslation("common");

  return (
    <div>
      <footer className="wrapperFooter ">
        <section className="wrapperInfo">
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <div className="row mt-3">
                  <div className="col-12 col-md-4 col-lg-4">
                    <h4 className="heading">{t("lifeuni_company")}</h4>
                    <p>Mã số doanh nghiệp 0314491207 do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp ngày 03/07/2017.</p>
                    <p>
                      <i className="fas fa-location-arrow mr-2"></i>
                      {t("lifeuni_company_address")}
                    </p>
                    <p>
                      <i className="fas fa-clock mr-2"></i>
                      {t("lifeuni_company_timeworking")}
                    </p>
                    <p>
                      <i className="fas fa-phone-alt mr-2"></i>0906 880 917 -
                      0901 883 917
                    </p>

                    <p>
                      <i className="fa fa-envelope mr-2"></i>
                      studentcare@soulretreats.info
                    </p>
                    <p>Người chịu trách nhiệm quản lý website: La Cẩm Tú</p>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <h4 className="heading">{t("connect_us")}</h4>
                    <a
                      href="https://www.facebook.com/theoriginsacademy"
                      className="text-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f mr-3"></i>
                    </a>

                    <a
                      href="https://www.youtube.com/@CoachVasTheOriginsVN"
                      className="text-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube mr-3"></i>
                    </a>
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="/chinh-sach"
                      style={{ color: "white" }}
                    >
                      <h4 className="heading2 mt-3">{t("policy")}</h4>
                    </NavLink>
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="/huong-dan-thanh-toan-online"
                      style={{ color: "white" }}
                    >
                      <h4 className="heading2 mt-3">{t("guide_payment")}</h4>
                    </NavLink>
                    <h4 className="heading2 mt-3">{t("customer_support")}</h4>
                    <p>
                      Đối với các thắc mắc về Dịch vụ Khách hàng. Chúng tôi cố
                      gắng trả lời tất cả các câu hỏi trong vòng 2 ngày làm
                      việc.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <h4 className="heading">{t("partner")}</h4>
                    <img src={logoVnPay} alt="logoVnpay" width={"250px"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>

      <ScrollTopButton />
    </div>
  );
}

export default LPEFooter;
