import React from "react";
import Guide01 from "assets/images/source/payment/payment-guide-01.png";
import Guide02 from "assets/images/source/payment/payment-guide-02.png";
import Guide03 from "assets/images/source/payment/payment-guide-03.png";
import Guide04 from "assets/images/source/payment/payment-guide-04.png";
import Guide05 from "assets/images/source/payment/payment-guide-05.png";
import Guide06 from "assets/images/source/payment/payment-guide-06.png";
function PaymentGuide(props) {
  return (
    <div
      className="payment-guide-body"
      style={{ background: "white", paddingBottom: "30px", paddingTop: "30px" }}
    >
      <div className="container">
        <h3 className="mt-4">HƯỚNG DẪN THANH TOÁN VNPAY TRÊN WEBSITE</h3>
        <p className="mt-3">
          Cổng thanh toán VNPAY là giải pháp thanh toán do Công ty Cổ phần Giải
          pháp Thanh toán Việt Nam (VNPAY) phát triển. Khách hàng sử dụng
          thẻ/tài khoản ngân hàng, tính năng QR Pay/VNPAY-QR được tích hợp sẵn
          trên ứng dụng Mobile Banking của các ngân hàng hoặc Ví điện tử liên
          kết để thanh toán các giao dịch và nhập mã giảm giá (nếu có)
        </p>
        <div className="d-flex justify-content-center">
          <img src={Guide01} alt="Hướng dẫn thanh toán" />
        </div>
        <div className="d-flex justify-content-center">
          <img src={Guide02} alt="Hướng dẫn thanh toán" />
        </div>
        <div className="d-flex justify-content-center">
          <img src={Guide03} alt="Hướng dẫn thanh toán" />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <img src={Guide04} alt="Hướng dẫn thanh toán" />
        </div>
        <b>
          1. Phương thức thanh toán qua “Ứng dụng thanh toán hỗ trợ VNPAY-QR”
        </b>
        <p className="mt-3">
          Bước 1: Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay
          hoặc Đặt hàng Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã
          đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh
          toán VNPAY và nhấn nút “Đặt hàng ngay”.
        </p>
        <p>
          Bước 2: Màn hình thanh toán chuyển sang giao diện cổng thanh toán
          VNPAY. Chọn phương thức “Ứng dụng thanh toán hỗ trợ VNPAY-QR”
        </p>
        <p>
          Bước 3: Hệ thống hiển thị mã QR cùng với số tiền cần thanh toán, Quý
          khách kiểm tra lại số tiền này. Sử dụng ứng dụng ngân hàng (theo danh
          sách liệt kê), chọn “Quét Mã” và tiến hành quét mã QR trên màn hình
          thanh toán website *Lưu ý: Mã QR có hiệu lực trong 15 phút Để quá
          trình thanh toán thành công, khách hàng vui lòng tham khảo trước các
          điều kiện và thao tác quét mã trên điện thoại để sẵn sàng, tránh sự cố
          hết thời gian ảnh hưởng đến thanh toán và mã khuyến mại của quý khách.
        </p>
        <p>
          Bước 4: Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất
          thanh toán Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được
          thông báo xác nhận đơn hàng đặt hàng thành công tại website
        </p>
        <div className="d-flex justify-content-center mb-3">
          <img src={Guide05} alt="Hướng dẫn thanh toán" />
        </div>
        <b>
          2. Phương thức thanh toán qua “Thẻ nội địa và tài khoản ngân hàng”
        </b>
        <p className="mt-3">
          Bước 1: Quý khách lựa chọn sản phẩm, dịch vụ và chọn Thanh toán ngay
          hoặc Đặt hàng Tại trang thanh toán, vui lòng kiểm tra lại sản phẩm đã
          đặt, điền đầy đủ thông tin người nhận hàng, chọn phương thức thanh
          toán VNPAY và nhấn nút “Đặt hàng ngay”.
        </p>
        <p>
          Bước 2: Màn hình thanh toán chuyển sang giao diện cổng thanh toán
          VNPAY. Chọn phương thức “Thẻ nội địa và tài khoản ngân hàng” và chọn
          ngân hàng muốn thanh toán thẻ trong danh sách
        </p>
        <p>
          Bước 3: Quý khách vui lòng thực hiện nhập các thông tin thẻ/tài khoản
          theo yêu cầu và chọn “Tiếp tục”. Mã OTP sẽ được gửi về điện thoại đăng
          ký, nhập mã OTP để hoàn tất giao dịch *Lưu ý: Giao dịch sẽ hết hạn sau
          15 phút
        </p>
        <p>
          Bước 4: Khi thực hiện thanh toán hoàn tất Quý khách sẽ nhận được thông
          báo xác nhận đơn hàng đặt hàng thành công tại website
        </p>
        <div className="d-flex justify-content-center mb-3">
          <img src={Guide06} alt="Hướng dẫn thanh toán" />
        </div>
        <b>
          3. Phương thức thanh toán qua “Thẻ thanh toán quốc tế (Visa,
          MasterCard, JCB, UnionPay)”
        </b>
        <p className="mt-3">
          Tương tự như phương thức thanh toán “Thẻ nội địa và tài khoản ngân
          hàng”
        </p>
        <b>4. Phương thức thanh toán qua “Ví điện tử VNPAY”</b>
        <p className="mt-3">
          Tương tự như phương thức thanh toán “Ứng dụng thanh toán hỗ trợ
          VNPAY-QR”
        </p>
        <hr />
        <h3 className="mt-4">CAM KẾT BẢO MẬT THANH TOÁN: </h3>
        <p className="mt-3">
          Hệ thống thanh toán trực tuyến trên theoriginsacademy.vn được cung cấp bởi đối tác cổng thanh
          toán VNPAY đã được cấp phép hoạt động hợp pháp tại Việt Nam. Theo đó, các tiêu chuẩn bảo
          mật thanh toán trực tuyến tại theoriginsacademy.vn đảm bảo tuân thủ theo các tiêu chuẩn bảo
          mật ngành.
        </p>
        <h3 className="mt-4">QUY ĐỊNH BẢO MẬT THANH TOÁN: </h3>
        <p className="mt-3">
          Theoriginsacademy.vn hợp tác với cổng thanh toán VNPAY. Hệ thống thanh toán của VNPAY
          đáp ứng chuẩn bảo mật thông tin của PCI Security Standards Council để bảo đảm việc bảo mật
          đối với thông tin cá nhân.
        </p>
        <ul>
          <li>Thông tin tài chính của Khách hàng sẽ được bảo vệ trong suốt quá trình giao dịch bằng giao
            thức SSL (Secure Sockets Layer).</li>
          <li>Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo việc truy cập tài khoản
            được xác thực.</li>
          <li>Tiêu chuẩn mã hóa MD5 128 bit.</li>
          <li>Các nguyên tắc và quy định bảo mật thông tin trong ngành tài chính ngân hàng theo quy
            định của Ngân hàng nhà nước Việt Nam.</li>
        </ul>
        <h3 className="mt-4">CHÍNH SÁCH BẢO MẬT GIAO DỊCH TRONG THANH TOÁN CỦA THEORIGINSACADEMY.VN ÁP DỤNG VỚI KHÁCH HÀNG:</h3>
        <ul>
          <li>Theoriginsacademy.vn cung cấp tiện ích lưu giữ token - chỉ lưu chuỗi đã được mã hóa bởi
            Đối Tác Cổng Thanh Toán cung cấp cho theoriginsacademy.vn. Theoriginsacademy.vn không
            trực tiếp lưu trữ thông tin thẻ khách hàng. Việc bảo mật thông tin thẻ thanh toán Khách hàng
            được thực hiện bởi Đối Tác Cổng Thanh Toán đã được cấp phép.</li>
          <li>Đối với thẻ quốc tế: thông tin thẻ thanh toán của Khách hàng mà có khả năng sử dụng để
            xác lập giao dịch không được lưu trên hệ thống của theoriginsacademy.vn. Đối Tác Cổng Thanh
            Toán sẽ lưu trữ và bảo mật.</li>
          <li>Đối với thẻ nội địa (internet banking), theoriginsacademy.vn chỉ lưu trữ mã đơn hàng, mã
            giao dịch và tên ngân hàng.</li>
        </ul>
        <p>Chúng tôi cam kết đảm bảo thực hiện nghiêm túc các biện pháp bảo mật cần thiết cho mọi hoạt
          động thanh toán thực hiện trên website theoriginsacademy.vn</p>
        <h3 className="mt-4">KÊNH HỖ TRỢ</h3>
        <p>Tổng đài: *3388 hoặc 1900 55 55 77</p>
        <p>
          Zalo OA:&nbsp;
          <a
            href="https://zalo.me/4134983655549474109"
            target="_blank"
            rel="noreferrer"
          >
            zalo.me/4134983655549474109
          </a>
        </p>
        <p>Email: hotro@vnpay.vn</p>
        <p>
          Fanpage:{" "}
          <a
            href="https://facebook.com/VNPAYQR.vn"
            target="_blank"
            rel="noreferrer"
          >
            facebook.com/VNPAYQR.vn
          </a>
        </p>
      </div>
    </div>
  );
}

export default PaymentGuide;
