import React, { useEffect, useState, useRef } from 'react';
import "./styles.scss";
import { isEmpty } from 'lodash';
import Loading from "share/loading";
import { mountainOverview, mountainExplaination, howToDoMountain, colorsDataOverView, comboSaleDetailsOverView, pyramidItemsLayoutOverview } from './const';
import ScrollParallax from '../../components/scrollParallax';
import Mount from "assets/images/course/course-online/mount.jpg";
import PyramidDisplay from 'app/modules/ClientModules/courses/courseOnline/components/pyramid';
import { stationItemsLayout, comboDataDetails } from '../fansipan/const';
import BestSellerImge from "assets/images/best-seller.png";
import GeneralCombo from "assets/images/course/course-online/goithanhvienGeneral.png";
import VIPCombo from "assets/images/course/course-online/goithanhvienvip.png";
import BasicCombo from "assets/images/course/course-online/goicoban.png";
import FormatNumberic from "share/formatNumber";
import Combo234 from "assets/images/course/course-online/goikynang.png";
import Combo567 from "assets/images/course/course-online/goituduy.png";
import Combo8910 from "assets/images/course/course-online/goivedich.png";
import RegisterForm from "share/registerForm";
import { arrayMountain } from 'app/modules/ClientModules/courses/courseOffline/const';
import { isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal, IconButton } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FSPElearning from "assets/images/course/course-online/titleFSP.png";
import shadows from '@mui/material/styles/shadows';

const MountainOverview = () => {
    const [prevIndex, setPrevIndex] = useState(null);
    const [step, setStep] = useState(0);
    const [dataReg, setDataReg] = useState({});
    const storedParams = localStorage.getItem("utm");
    const parsedParams = JSON.parse(storedParams);
    const [data, setData] = useState(pyramidItemsLayoutOverview);
    const [selectedPack, setSelectedPack] = useState(null);
    const [selectedComco, setSelectedCombo] = useState(null);
    const [indexHover, setIndexHovered] = useState(null);
    const [stationData, setStationData] = useState(stationItemsLayout);
    const freeVideoRef = useRef(null);
    const overviewRef = useRef(null);
    const prizeRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [popupVisible, setPopupVisible] = useState([false, false, false]);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handlePackClick = (pack) => {
        setSelectedPack(pack);
        switch (pack.label) {
            case "2":
            case "3":
            case "4":
                console.log("----floor1", pack);
                break;
            case "5":
            case "6":
            case "7":
                console.log("----floor2", pack);
                break;
            case "8":
            case "9":
            case "10":
                console.log("----floor3", pack);
                break;
            default:
                freeVideoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        // scrollToVideo();
    };
    const renderPrice = (price, color) => {
        return (
            <div className="price-fsp" style={{
                background: `-webkit-linear-gradient(${color}, white)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: '28px'
            }}>
                <FormatNumberic num={price} />
            </div>
        );
    };
    const renderPromoPrice = (price) => {
        return (
            <div className="price-promo-fsp">
                <FormatNumberic num={price} />
            </div>
        );
    };
    const renderSwitch = () => {
        switch (step) {
            case 0:
                return (
                    <RegisterForm
                        title={"Thông tin đăng ký !"}
                        course={arrayMountain[0].mountainTag}
                        mountain={"FSP"}
                        setStep={(i) => {
                            setStep(i);
                        }}
                        setOpenModal={(i) => {
                            setOpenModal(i);
                        }}
                        setDataReg={(i) => {
                            setDataReg(i);
                        }}
                        parsedParams={parsedParams}
                    />
                );
            case 1:
                return (
                    <div className="popup">
                        <div className="popup-background"></div>
                        <div className="overlay" />
                        <div className="col-12 position-relative">
                            <div className="px-5 py-5">
                                <div className="row">
                                    <h2>CHÚC MỪNG BẠN </h2>
                                    <h4 style={{ textAlign: "center" }}>
                                        ĐÃ ĐĂNG KÝ KHÓA HỌC ONLINE
                                    </h4>
                                    <h4 style={{ textAlign: "center" }}>
                                        E-LEARNING SKILLSET
                                    </h4>
                                </div>
                                <div className="row mt-4 text-center">
                                    <p> Hành trình phát triển kỹ năng của bạn chính thức bắt đầu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <></>;
        }
    };
    const renderPopUpRegister = () => {
        const style = {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            position: "absolute",
            display: "block",
            width: isMobile ? "90%" : "50%",
            maxHeight: "80%",
            overflow: isMobile ? "scroll" : "auto",
            "&:focus-visible": {
                outline: "0px solid #1976d2",
                boxShadow: 12,
            },
        };
        return (
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setStep(0);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={
                    {
                        // borderRadius: "40px",
                    }
                }
            >
                <Box sx={style}>
                    <IconButton
                        onClick={() => {
                            setOpenModal(false);
                            setStep(0);
                        }}
                        sx={{ position: "absolute", top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {renderSwitch()}
                </Box>
            </Modal>
        );
    };

    console.log("prevIndex", prevIndex);
    const updateColors = (index) => {
        if (prevIndex !== null) {
            setData(pyramidItemsLayoutOverview);
        }

        let popupVisibleState = [false, false, false];

        if (index !== null) {
            const updatedData = data.map((floor, floorIndex) =>
                floor.map(pack => {
                    let newColor = pack.color;
                    switch (index) {
                        case 0:
                            if (pack.label !== "1") {
                                newColor = "#FFFF2E";
                                popupVisibleState = [true, true, true];
                            }
                            break;
                        case 1:
                            if (floorIndex === 3 && pack.label !== "1") {
                                newColor = "#FFFF2E";
                                popupVisibleState = [true, false, false];
                            }
                            break;
                        case 2:
                            if (floorIndex === 2) {
                                newColor = "#FFFF2E";
                                popupVisibleState = [false, true, false];
                            }
                            break;
                        case 3:
                            if (floorIndex === 0 || floorIndex === 1) {
                                newColor = "#FFFF2E";
                                popupVisibleState = [false, false, true];
                            }
                            break;
                        default:
                            break;
                    }
                    return { ...pack, color: newColor };
                })
            );
            setData(updatedData);
        }
        const updatedStationData = stationData.map((station, i) => {
            let updatedColor = null;
            switch (index) {
                case 0:
                    updatedColor = "#FFFF2E";
                    popupVisibleState = [true, true, true];
                    break;
                case 1:
                    if (i === 0) {
                        updatedColor = "#FFFF2E";
                        popupVisibleState = [true, false, false];
                    }
                    break;
                case 2:
                    if (i === 1) {
                        updatedColor = "#FFFF2E";
                        popupVisibleState = [false, true, false];
                    }
                    break;
                case 3:
                    if (i === 2) {
                        updatedColor = "#FFFF2E";
                        popupVisibleState = [false, false, true];
                    }
                    break;
                default:
                    break;
            }
            return { ...station, color: updatedColor };
        });
        setPopupVisible(popupVisibleState);
        setStationData(updatedStationData);
        setPrevIndex(index);
    };
    const handleScrollToOverview = () => {
        overviewRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const handleScrollToPrize = () => {
        prizeRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const handleMouseMove = (e) => {
        setPopupPosition({ top: e.clientY + 10 + 900, left: e.clientX + 10 });
    };
    const handlePopupClick = () => {
        if (hoveredItem) {
            console.log("item :", hoveredItem);
        }
    };
    const PopupCard = ({ item, onClose, style }) => {
        return (
            <div className="popup-card" onClick={onClose} style={style}>
                <div className="popup-content text-center" style={{ backgroundImage: `url(${item.mountainURL})` }}>
                    <h1>{item.mountainName}</h1>
                    <h3>{item.mountainHeight}</h3>
                    <p>Nhấp Vào Núi Để Xem Thêm</p>
                </div>
            </div>
        );
    };
    const renderPopup = hoveredItem && (
        <PopupCard
            item={hoveredItem}
            onClose={handlePopupClick}
            style={{
                position: 'absolute',
                top: popupPosition.top,
                left: popupPosition.left,
            }}
        />
    );
    const handleMountainNavigate = (url) => {
        navigate(url);
    };
    const renderFSPPyramid = () => {
        return (
            <div className="row background py-20">
                <div className="col-md-5">
                    <PyramidDisplay data={data} onPackClick={handlePackClick} stationData={stationData} popupVisible={popupVisible} />
                </div>
                <div className="col-md-7">
                    <div className="color-box-row">
                        {colorsDataOverView.map((item, index) => (
                            <div className="color-box-wrapper" key={index}>
                                <div className="color-box-item" style={{ backgroundColor: item.color }}>
                                </div>
                                <p className="description" style={{ color: 'black' }}>{item.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            {comboDataDetails.map((card, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="container d-flex h-100 justify-content-center p-0">
                                        <div
                                            className="shaking-card "
                                            onMouseEnter={isMobile || isTablet ? undefined : () => {
                                                setIndexHovered(index);
                                                updateColors(index);
                                            }}
                                            onMouseLeave={() => {
                                                updateColors(null);
                                                setPopupVisible([false, false, false]);
                                            }}
                                            onClick={isMobile || isTablet ? () => {
                                                setIndexHovered(index);
                                                updateColors(index);
                                            } : undefined}
                                            style={{ fontWeight: 'bolder' }}
                                        >
                                            <p>{card.defaultText}</p>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row my-10 p-0">
                            {comboSaleDetailsOverView
                                .filter(card => card.keyWord.includes(indexHover))
                                .map((detailCard, detailIndex) => (
                                    <div className="col h-100" key={detailIndex}>
                                        <div
                                            className={`shaking-card-detail d-flex h-100 flex-column ${detailCard.isBestSeller ? 'best-seller-border' : ''}`}
                                            onMouseEnter={() => {
                                                (detailIndex === 0) ? updateColors(indexHover) : updateColors(0)
                                            }}
                                            onMouseLeave={() => {
                                                updateColors(null);
                                                setPopupVisible([false, false, false]);
                                            }}
                                            onClick={() => {
                                                setOpenModal(true);
                                            }}
                                        >
                                            {detailCard.isBestSeller && (
                                                <div className="best-seller-badge">
                                                    <img src={BestSellerImge} alt="Best Seller" />
                                                </div>
                                            )}
                                            <img
                                                src={
                                                    (() => {
                                                        switch (detailIndex) {
                                                            case 0:
                                                                switch (indexHover) {
                                                                    case 0:
                                                                        return BasicCombo;
                                                                    case 1:
                                                                        return Combo234;
                                                                    case 2:
                                                                        return Combo567;
                                                                    case 3:
                                                                        return Combo8910;
                                                                    default:
                                                                        return BestSellerImge;
                                                                }
                                                            case 1:
                                                                return GeneralCombo;
                                                            case 2:
                                                                return VIPCombo;
                                                            default:
                                                                return BestSellerImge;
                                                        }
                                                    })()
                                                }
                                                alt="Combo"
                                                className="title-image"
                                            />
                                            <ul className="custom-list">
                                                {detailCard.detail.map((item, i) => (
                                                    <li key={i}>{item}</li>
                                                ))}
                                                {detailCard.prerequisite && (
                                                    <li key="prerequisite" style={{ color: 'red' }}>
                                                        {detailCard.prerequisite}
                                                    </li>
                                                )}
                                            </ul>
                                            <div>
                                                {renderPromoPrice(detailCard.promoprice)}
                                            </div>
                                            <div>
                                                {renderPrice(detailCard.price, detailCard.colorPromo)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

        )
    };
    return (!isEmpty(mountainOverview)) ? (
        <div style={{
            background: `url(${Mount})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            height: '100%',
        }}>

            {renderPopUpRegister()}
            <ScrollParallax />
            <div className='pt-20'>
                <div className="container-temp">
                    {mountainOverview.map((item, index) => {
                        let className = '';
                        switch (index) {
                            case 0:
                                className += ' first-mountain';
                                break;
                            case 1:
                                className += ' second-mountain';
                                break;
                            case 2:
                                className += ' third-mountain';
                                break;
                            default:
                                className += 'fourth-mountain';
                                break;
                        }

                        return (
                            <div
                                key={item.id}
                                className={className}
                                style={{
                                    background: hoveredItem === item ? 'blue' : '#ADD8E6',
                                }}
                                onClick={handleScrollToOverview}
                                onMouseEnter={() => setHoveredItem(item)}
                                onMouseLeave={() => setHoveredItem(null)}
                                onMouseMove={handleMouseMove}
                            />
                        );
                    })}
                    {renderPopup}
                </div>
            </div>
            <div className='description-overview-mountain' style={{
                backgroundColor: hoveredItem ? 'blue' : '#ADD8E6'
            }}>
                <div className="overview-details">
                    <div className="details-content text-start">
                        <h3 className="text-center" style={{ paddingBottom: '20px' }}>{mountainExplaination.title}</h3>
                        <p>{mountainExplaination.explaination}</p>
                        <p style={{ color: 'red' }}>{mountainExplaination.note}</p>
                        {/* <p style={{ color: 'blue' }}>{mountainExplaination.pressingRegister}</p> */}
                    </div>
                    <button className="details-button" onClick={handleScrollToOverview}>{mountainExplaination.buttonTitle}</button>
                </div>

                <div className="overview-details">
                    <div className="details-content text-start">
                        <h3 className='text-center' style={{ paddingBottom: '10px' }}>{howToDoMountain.title}</h3>
                        <p>{howToDoMountain.explaination}</p>
                        <ul>
                            <li>1. Mỗi núi gồm <span style={{ color: 'red' }}>3</span> tầng </li>
                            <li>2. Mỗi tầng gồm <span style={{ color: 'green' }}>3 hoặc 4</span> video bài học và có trạm dừng chân bí mật</li>
                            <li>3. Sau khi xem video bài học xong sẽ được trả lời các câu hỏi để <span style={{ color: 'orange', fontWeight: 'bolder' }}>NHẬN THƯỞNG</span></li>
                            <li display="none" style={{ listStyleType: 'none', fontWeight: 'bold', color: 'red', paddingTop: '5px' }}>Lưu ý : Phải hoàn thành núi theo thứ tự từ trái sang phải (nhỏ đến lớn)
                                <br />
                                để có thể hiểu hoàn toàn
                            </li>
                        </ul>
                    </div>

                    <button className="details-button" onClick={handleScrollToPrize}>{howToDoMountain.buttonTitle}</button>
                </div>
            </div>
            <h1 className="cool-heading py-10">GIỚI THIỆU GIÁ VÀ CÁC KHUYẾN MÃI THEO GÓI CỦA 1 NÚI HỌC</h1>
            <h5 className="cool-subheading">Di chuột vào từng trạm, combo để khám phá</h5>
            {renderFSPPyramid()}
            <div className="card-container pt-20" ref={overviewRef}>
                <h1 className="cool-heading py-10">GIỚI THIỆU CÁC NÚI VÀ KHÓA HỌC KHÁC NHAU</h1>
                {mountainOverview.map((item) => (
                    <div key={item.mountainId} className={`card ${item.mountainId % 2 === 0 ? 'right' : 'left'} p-0`} style={{ border: 'none', borderRadius: '15px' }}
                    >
                        <div className="row">
                            {item.mountainId % 2 === 0 ? (
                                <>
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <button className="btn btn-primary mountain-button" onClick={() => handleMountainNavigate(item.mountainURLNavigate)}>
                                            Đi Tới <br /> {item.mountainName}
                                        </button>
                                    </div>
                                    <div className="col-9 text-start" style={{
                                        borderRadius: '15px',
                                        backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url(${item.mountainURL})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}>
                                        <h1 className="card-content mountain-title">
                                            {item.mountainName}
                                        </h1>
                                        <div className="card-content mountain-height">
                                            <strong>Độ cao:</strong> {item.mountainHeight}
                                        </div>
                                        <div className="card-content mountain-summary">
                                            <strong>Đôi nét về {item.mountainName}:</strong> {item.mountainSummary}
                                        </div>
                                        <div className="card-content mountain-summary">
                                            <strong>Thời lượng:</strong> {item.mountainTime}
                                        </div>
                                        <p className="mountain-prequisite">
                                            {item.mountainPrequisite}
                                        </p>
                                    </div>

                                </>
                            ) : (
                                <><div className="col-9 text-start mountain-info" style={{
                                    borderRadius: '15px',
                                    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url(${item.mountainURL})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }}>
                                    <h1 className="card-content mountain-title">
                                        {item.mountainName}
                                    </h1>
                                    <div className="card-content mountain-height">
                                        <strong>Độ cao:</strong> {item.mountainHeight}
                                    </div>
                                    <div className="card-content mountain-summary">
                                        <strong>Đôi nét về {item.mountainName}:</strong> {item.mountainSummary}
                                    </div>
                                    <div className="card-content mountain-summary">
                                        <strong>Thời lượng:</strong> {item.mountainTime}
                                    </div>
                                    <p className="mountain-prequisite">
                                        {item.mountainPrequisite}
                                    </p>
                                </div>
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <button className="btn btn-primary mountain-button" onClick={() => handleMountainNavigate(item.mountainURLNavigate)}>
                                            Đi Tới <br /> {item.mountainName}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="details-prize-card-content pt-20" ref={prizeRef}>
                <div className="details-prize-card">
                    <h3 className="details-prize-card-title">CÁCH TÍNH ĐIỂM VÀ GIẢI THƯỞNG</h3>
                    <ul className='text-start'>
                        <li> Mỗi khi trả lời đúng <span style={{ color: 'red' }}>1</span> câu hỏi , bạn sẽ được <span style={{ color: 'green' }}>50 năng lượng</span> vào tài khoản </li>
                        <li> Mỗi Video sẽ có <span style={{ color: 'red' }}>3</span> câu hỏi sau khi xem xong , mỗi trạm cũng có một vài câu hỏi </li>
                        <li> Số năng lượng tối đa bạn có thể đạt được của mỗi núi là <span style={{ color: 'green' }}>3000</span> </li>
                        <li> Mỗi người chỉ có thể trả lời <span style={{ color: 'red' }}>1</span> lần</li>
                        <li> Bạn có thể quy đổi năng lượng này thành các voucher giảm giá các lớp học OFFLINE của The Origin (giá trị quy đổi là 1.000VND/năng lượng) </li>
                        <li> Bạn cũng có thể tích lũy năng lượng để có cơ hội để tham gia leo núi Fansipan cùng thầy VAS <span style={{ color: 'orange' }}>MIỄN PHÍ</span> </li>
                        <li style={{ color: 'lightcoral' }}> (1 người trong top 10 điểm năng lượng tích lũy mỗi kỳ chốt sẽ được chọn ngẫu nhiên để leo núi) </li>
                    </ul>
                    <p className="details-prize-card-description">
                    </p>
                </div>
            </div>

            <h1 className="cool-heading pt-10" ref={freeVideoRef}>SOUL RETREATS GỬI TẶNG BẠN VIDEO MIỄN PHÍ ĐẦU TIÊN</h1>
            <h5 className="cool-subheading pb-10">(Video bài học số 1 của tháp đầu tiên - FANSIPAN  )</h5>

            <div className="video-container">
                <iframe
                    src="https://www.youtube.com/embed/9ReH5rorvWA"
                    title="KHÓA HỌC E-LEARNING SKILL SET"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen>
                </iframe>
            </div>
            <div className='pb-20'></div>
            <div className="tfs-fixed-bottom">
                <div className="container d-flex align-items-center justify-content-center h-100">
                    <div className="img-register">
                        <Button
                            className=" tfs-btn-register animate-ring"
                            onClick={() => {
                                setOpenModal(true);
                            }}
                        >
                            Đăng ký
                        </Button>
                    </div>
                </div>
            </div>
        </div >

    ) : (
        <Loading />
    );
}

export default MountainOverview;