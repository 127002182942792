import React, { useState, useEffect } from 'react';
import "./styles.scss";

const CountdownClock = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                Ngày: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Giờ: Math.floor((difference / (1000 * 60 * 60)) % 24),
                Phút: Math.floor((difference / 1000 / 60) % 60),
                Giây: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) return;

        timerComponents.push(
            <span key={interval} className="countdown-item">
                {timeLeft[interval]} {interval}{' '}
            </span>
        );
    });

    return (
        <div className="countdown-container">
            {timerComponents.length ? timerComponents : <span>Hết thời gian!</span>}
        </div>
    );
};

export default CountdownClock;
