import React, { useState } from 'react';
import './styles.scss';

const PopupQuestion = ({ currentVideoData, openQuestion, setOpenQuestion, onSubmit, closeOnOutsideClick }) => {
    const [userAnswers, setUserAnswers] = useState({});
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const questionsPerPage = 4;

    const handleAnswerChange = (questionIndex, choiceIndex) => {
        setUserAnswers({
            ...userAnswers,
            [questionIndex]: choiceIndex,
        });
        setError('');
    };

    const handleSubmit = () => {
        const unansweredQuestions = currentVideoData?.question.some((q, index) => userAnswers[index] === undefined);
        if (unansweredQuestions) {
            setError('Hãy trả lời hết các câu hỏi');
            return;
        }
        onSubmit(userAnswers);
        setOpenQuestion(false);
    };

    const handleOutsideClick = (e) => {
        if (closeOnOutsideClick && e.target.className === 'question-modal') {
            setOpenQuestion(false);
        }
    };

    const handleNextPage = () => {
        if ((currentPage + 1) * questionsPerPage < currentVideoData?.question.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startIndex = currentPage * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    const currentQuestions = currentVideoData?.question.slice(startIndex, endIndex);

    return (
        <>
            {openQuestion && (
                <div className="question-modal" onClick={handleOutsideClick}>
                    <div className="question-modal-content">
                        <span className="close" onClick={() => setOpenQuestion(false)}>&times;</span>
                        <h3>{currentVideoData?.title}</h3>
                        {currentQuestions.map((q, questionIndex) => (
                            <div key={q.id} className="question">
                                <h6 style={{ fontWeight: 'bolder', paddingBottom: '3px', paddingTop: '3px', color: 'green' }}>{q.question}</h6>
                                <ul style={{ listStyleType: 'none' }}>
                                    {q.answerchoice.map((choice, choiceIndex) => (
                                        <li className="text-start" key={choiceIndex}>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name={`question-${startIndex + questionIndex}`}
                                                    value={choiceIndex}
                                                    onChange={() => handleAnswerChange(startIndex + questionIndex, choiceIndex)}
                                                />
                                                {choice}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className="pagination-controls">
                            <button onClick={handlePreviousPage} disabled={currentPage === 0}>Trang sau</button>
                            <button onClick={handleNextPage} disabled={(currentPage + 1) * questionsPerPage >= currentVideoData?.question.length}>Trang trước</button>
                        </div>
                        <button className="submit-question-btn" onClick={handleSubmit}>Tiếp Theo</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupQuestion;